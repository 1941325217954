
import { Options, Vue } from 'vue-class-component';
import { CustomerClient, ExhibitionItemsClient } from '@/services/services'
import InfoModal from '@/components/modals/infoModal.vue';
import SwiperGallery from '@/components/modals/swiperGalleryModal.vue';
import * as OM from '@/model';

@Options({
    components: {
    }
})
export default class FavoriteDetail extends Vue {

    opera: OM.ExhibitionItemVm = new OM.ExhibitionItemVm();

    showMore: boolean = false;
    needLessButton: boolean = false;

    loaded: boolean = false;
   
    created() {
        var exhibitionItemIdentifier = this.$route.params.exhibitionItemIdentifier.toString();
        
        ExhibitionItemsClient.getExhibitionItemDetail(exhibitionItemIdentifier)
        .then(x => {
            this.opera = x;                        
            this.loaded = true;

            this.$nextTick(() => {
                let operaDescriptionEl = <HTMLElement>this.$refs.operaDescription;
                let windowTreshold = window.innerHeight * 19 / 100;
                if(parseFloat(getComputedStyle(operaDescriptionEl).height) > windowTreshold){
                    this.showMore = true;
                    this.needLessButton = true;
                }
            })
        },
        error => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_ItemNotFound", "Item not found"),
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$router.back();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    showSwiperGalleryModal(gallery: OM.LocalizedThumbnailItem[], index: number, isVideo: boolean) {
        var urls = [];
        gallery.forEach(element => {
            urls.push(element.url);
        });

        this.$opModal.show(SwiperGallery, {
            isVideo: isVideo,
            gallery: urls,
            index: index
        });
    }

    toggleFavorite() {
        if(!this.opera.inFavorite) {
            CustomerClient.addFavorite(this.opera.identifier, false)
            .then(x => {})
        }
        else {
            CustomerClient.removeFavorite(this.opera.identifier, false)
            .then(x => {})
        }

        this.opera.inFavorite = !this.opera.inFavorite;
    }
}
